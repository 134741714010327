import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import * as Sentry from "@sentry/remix";
import { startTransition, StrictMode, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: "https://ebbfed9ea2ae45d4bcad30d65eb896d3@o169618.ingest.us.sentry.io/1271028",
    release: import.meta.env.VITE_SENTRY_RELEASE,
    tracesSampleRate: 1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,

    integrations: [
      Sentry.browserTracingIntegration({
        useEffect,
        useLocation,
        useMatches
      }),
      Sentry.replayIntegration()
    ]
  });
}

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>
  );
});
